import React from 'react';

// External Components
import { Section, Heading, Paragraph, Box } from '@thepuzzlers/pieces';

// Local Components
import { Spacer } from 'components';

// Self Components
import { MultipleFloorPlan, FloorPlanWithoutLevel } from './components';

// Utils
import { convertApiHtmlText } from 'graphqlHooks/helper';
import { GridWrapper } from '@thepuzzlers/pieces/dist/thepuzzlers-pieces.cjs.prod';

export const FloorPlans = ({ staticData, cmsData }) => {
  const { modules, living_area, width, length, footprint } = cmsData;

  return (
    // Markup
    <Box as="section" id="product-detail-page__floor-plans">
      <GridWrapper>
        <Headline data={convertApiHtmlText(staticData.title.html)} />
      </GridWrapper>
      <HeadSpacer />
      {cmsData?.floor_plan && cmsData?.rooms && (
        <FloorPlanWithoutLevel
          staticData={staticData}
          modules={modules}
          livingArea={living_area}
          width={width}
          length={length}
          footprint={footprint}
          rooms={cmsData.rooms}
          sketchImage={cmsData.floor_plan}
        />
      )}
      {/* Multiple Floor plan */}
      {cmsData?.floor_plans && (
        <MultipleFloorPlan
          staticData={staticData}
          floorPlans={cmsData.floor_plans}
        />
      )}
    </Box>
  );
};

// Elements

const Headline = ({ data }) => (
  <Heading
    as="h2"
    dangerouslySetInnerHTML={{
      __html: data
    }}
    sx={{
      gridColumn: [
        '1/span 11',
        '1/span 11',
        '1/span 20',
        '1/span 17',
        '1/span 15',
        '2/span 12'
      ],
      fontFamily: 'primary.normal',
      fontSize: ['2.4rem', '3.5rem', '3.5rem', '3rem', '3.8rem', '4rem'],
      fontFeatureSettings:
        "'salt' on, 'ss01' on, 'ss02' on, 'ss03' on, 'ss04' on, 'ss05' on, 'ss08' on, 'calt' off",
      lineHeight: 1.2,
      '& > span': {
        fontFamily: 'primary.bold'
      }
    }}
  />
);

const HeadSpacer = () => {
  return (
    <Spacer height={['7.4rem', '6.6rem', '6rem', '5rem', '9rem', '10rem']} />
  );
};
