import { gsap } from 'gsap';

export const revealHeader = () => {
  let tl = gsap.timeline();

  tl.fromTo(
    '.product-detail-page__header__image',
    { autoAlpha: 0, y: 200 },
    { autoAlpha: 1, y: 0, duration: 1.2, ease: 'power4.out' }
  ).fromTo(
    [
      '.product-detail-page__header__headline',
      '.product-detail-page__header__info-group',
      '.product-detail-page__header__caption',
      '.product-detail-page__header__description',
      '.__award-image'
    ],
    { autoAlpha: 0, y: 50 },
    { autoAlpha: 1, y: 0, duration: 0.6, stagger: 0.2, ease: 'power1.out' },
    '-=0.2'
  );

  return () => {
    tl.pause(0).kill(true);
  };
};
