import React from 'react';

// External Components
import { Heading, Box, Paragraph } from '@thepuzzlers/pieces';

// Self Component
import { FloorInfoCaption, FloorInfoList, FloorInfoTitle } from '.';

// Utils

export const FloorInfo = ({
  data,
  listTitle,
  listCaption,
  number = '',
  price,
  name,
  sx
}) => {
  return (
    <Box
      className="__floor-info"
      sx={{
        mt: ['4rem', '5rem', '4rem', '4rem', 0, 0],
        alignSelf: 'start',
        ...sx
      }}>
      <FloorInfoTitle
        title={listTitle}
        number={number}
        price={price}
        name={name}
      />
      <FloorInfoList data={data} />
      {!!listCaption && <FloorInfoCaption text={listCaption} />}
    </Box>
  );
};
