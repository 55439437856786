import React from 'react';
import { FloorPlanWithoutLevel } from './FloorPlanWithoutLevel';
import { DividerLine } from 'components';
import { FloorPlanWithLevels } from './FloorPlanWithLevels';
import { GridWrapper } from '@thepuzzlers/pieces/dist/thepuzzlers-pieces.cjs.prod';

export const MultipleFloorPlan = ({ staticData, floorPlans }) => {
  return floorPlans.map((plan, index) => {
    const {
      modules,
      living_area,
      length,
      width,
      price,
      rooms,
      footprint,
      image,
      name
    } = plan;

    return (
      <React.Fragment key={index}>
        {index !== 0 && <Divider />}
        {/* Plan without level */}
        {plan?.levels && plan?.levels?.length === 0 ? (
          <FloorPlanWithoutLevel
            number={index + 1}
            key={index}
            staticData={staticData}
            modules={modules}
            livingArea={living_area}
            width={width}
            length={length}
            footprint={footprint}
            rooms={rooms}
            sketchImage={image}
            price={price}
            name={name}
          />
        ) : (
          <FloorPlanWithLevels
            number={index + 1}
            key={index}
            staticData={staticData}
            modules={modules}
            livingArea={living_area}
            width={width}
            length={length}
            footprint={footprint}
            rooms={rooms}
            levels={plan.levels}
            price={price}
            name={name}
          />
        )}
      </React.Fragment>
    );
  });
};

const Divider = () => {
  return (
    <GridWrapper>
      <DividerLine
        sx={{
          gridColumn: ['1/13', '1/13', '1/25', '1/25', '1/25', '1/25'],
          my: ['10rem']
        }}
      />
    </GridWrapper>
  );
};
