import React from 'react';

// External Components
import { Section, Heading, Box, Paragraph } from '@thepuzzlers/pieces';

// Local Components
import {
  ShortInformationBlock,
  ImageContainer,
  ImageWithLinearGradient
} from 'components';

// Utils
import { formatSquareMeterWithSuperscript, getPlanPrice } from 'utils';
import { convertApiHtmlText } from 'graphqlHooks/helper';

// Animations
import { revealHeader } from './animations';
import { fullWidthMinusMargins } from '@thepuzzlers/pieces';

export const Header = ({
  staticData,
  cmsData: {
    name,
    image,
    living_area,
    price,
    production_time,
    long_description
  }
}) => {
  React.useEffect(() => {
    const killAnimation = revealHeader();

    return killAnimation;
  }, []);

  return (
    // Markup
    <Section as="header" id="product-detail-page__header">
      <ImageWrapper {...image} />
      <Headline data={name} />
      <ShortInformationGroup
        staticData={staticData}
        livingArea={living_area}
        price={price}
        productionTime={production_time}
      />
      <Caption label={staticData.turnkey_label} price={price} />
      <Description
        data={formatSquareMeterWithSuperscript(
          convertApiHtmlText(long_description.html)
        )}
      />
      <Award data={staticData.awward_image} />
    </Section>
  );
};

// Elements

const Headline = ({ data }) => (
  <Heading
    as="h1"
    className="product-detail-page__header__headline"
    sx={{
      color: 'white',
      fontFamily: 'primary.normal',
      fontSize: ['2.4rem', '3.2rem', '3.2rem', '2.8rem', '3.6rem', '4rem'],
      gridColumn: [
        '2/span 10',
        '2/span 10',
        '2/span 16',
        '2/span 16',
        '2/span 16',
        '2/span 11'
      ],
      gridRow: 1,
      lineHeight: 1,
      mt: ['2.4rem', '3rem', '2.4rem', '2rem', '2.3rem', '4.5rem'],
      zIndex: 1,
      alignSelf: 'center'
    }}
    dangerouslySetInnerHTML={{ __html: data?.html || data }}
  />
);

const ImageWrapper = ({ image, alt }) => (
  <ImageWithLinearGradient
    className="product-detail-page__header__image"
    src={image}
    alt={alt}
    imgStyle={{
      objectPosition: 'center bottom'
    }}
    sx={{
      gridColumn: ['1/13', '1/13', '1/25', '1/25', '1/25', '1/25'],
      gridRow: [
        '1/span 3',
        '1/span 3',
        '1/span 3',
        '1/span 3',
        '1/span 3',
        '1/span 2'
      ],
      height: ['51.9rem', '82.8rem', '60rem', '40.4rem', '58.8rem', '55.9rem'],
      mx: [fullWidthMinusMargins[0], 0, 0, 0, 0, 0]
    }}
    isToBottom
  />
);

const ShortInformationGroup = ({
  staticData: { living_space_label, price_label, production_time_label },
  livingArea,
  price,
  productionTime
}) => (
  <Box
    className="product-detail-page__header__info-group"
    sx={{
      alignSelf: 'start',
      display: 'grid',
      gap: ['3rem 5.9rem', '3.6rem 5.4rem', '4.2rem', '3.7rem', '5rem', '4rem'],
      gridColumn: [
        '2/span 10',
        '2/span 9',
        '2/span 15',
        '2/span 15',
        '2/span 13',
        '12/span 12'
      ],
      gridRow: [2, 2, 2, 2, 2, 1],
      gridTemplateColumns: [
        '1fr 1fr',
        '1fr 1fr',
        'repeat(3, 1fr)',
        'repeat(3, 1fr)',
        'repeat(3, 1fr)',
        'repeat(3, 1fr)'
      ],
      mt: ['3.7rem', '4.9rem', '5.1rem', '2.4rem', '2.4rem', '5.4rem'],
      zIndex: 1
    }}>
    <ShortInformationBlock
      label={living_space_label}
      text={formatSquareMeterWithSuperscript(livingArea)}
      isLight
    />
    <ShortInformationBlock
      label={price_label}
      text={getPlanPrice(price.replace(price_label, ''))}
      isLight
    />
    <ShortInformationBlock
      label={production_time_label}
      text={productionTime}
      isLight
    />
  </Box>
);

const Caption = ({ label, price }) => {
  return (
    <Paragraph
      className="product-detail-page__header__caption"
      dangerouslySetInnerHTML={{
        __html: `${label}: <span>${getPlanPrice(price)}</span>`
      }}
      sx={{
        fontFamily: 'primary.normal',
        fontSize: ['1.6rem', '2rem', '1.8rem', '1.4rem', '1.8rem', '1.8rem'],
        gridColumn: [
          '1/span 11',
          '1/span 11',
          '1/span 15',
          '1/span 10',
          '1/span 10',
          '1/span 8'
        ],
        lineHeight: 1.5,
        mt: ['2.4rem', '2.4rem', '3rem', '2.4rem', '2.9rem', '3.7rem'],
        '& > span': {
          fontFamily: 'primary.bold'
        }
      }}
    />
  );
};

const Description = ({ data }) => (
  <Paragraph
    className="product-detail-page__header__description"
    dangerouslySetInnerHTML={{
      __html: data
    }}
    sx={{
      fontFamily: 'primary.normal',
      fontFeatureSettings:
        "'salt' on, 'ss01' on, 'ss02' on, 'ss03' on, 'ss04' on, 'ss05' on, 'ss08' on, 'calt' off",
      fontSize: ['1.8rem', '2.4rem', '2.2rem', '1.8rem', '2.8rem', '2.4rem'],
      gridColumn: [
        '1/13',
        '1/13',
        '1/span 22',
        '1/span 20',
        '1/span 21',
        '1/span 17'
      ],
      lineHeight: 1.5,
      mt: ['2.7rem', '3.2rem', '1.8rem', '1.8rem', '2.2rem', '1.6rem'],
      '& > span': {
        fontFamily: 'primary.bold'
      }
    }}
  />
);

const Award = ({ data: { image, alt } }) => {
  return (
    <ImageContainer
      className="__award-image"
      src={image}
      imageAlt={alt}
      sx={{
        width: ['9.2rem', '15rem', '13rem', '12rem', '9rem', '9.2rem'],
        gridColumn: [
          '5/ span 4',
          '5/ span 4',
          '9/ span 8',
          '9/ span 8',
          '22/25',
          '22/25'
        ],
        justifySelf: ['center', 'center', 'center', 'center', 'right', 'right'],
        alignSelf: [null, null, null, null, 'start', 'start'],
        mt: ['12rem', '19rem', '17rem', '10rem', '2.2rem']
      }}
    />
  );
};
