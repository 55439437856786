import React from 'react';

// External Components
import { Paragraph } from '@thepuzzlers/pieces';

export const FloorInfoCaption = ({ text, sx }) => {
  return (
    <Paragraph
      sx={{
        fontFamily: 'body.normal',
        lineHeight: 1.25,
        fontSize: ['1.2rem', '1.5rem', '1.4rem', '1.4rem', '1.4rem', '1.4rem'],
        mt: ['2.4rem', '2.7rem', '3rem', '2.8rem', '2.4rem', '2.4rem'],
        ...sx
      }}>
      {text}
    </Paragraph>
  );
};
