import React from 'react';

// External Components
import { Section, Paragraph, Box, Heading } from '@thepuzzlers/pieces';

// Local Components
import { ListDot, DividerLine } from 'components';
import { convertApiHtmlText } from 'graphqlHooks/helper';

export const Services = ({
  data: { services, richtexttitle: title, extra_services }
}) => {
  return (
    <Section id="product-detail-page__services">
      <Title title={title.html} />
      {services.map((service, index) => (
        <ServiceBlock key={index} data={service.data} />
      ))}
      {extra_services.map((service, index) => (
        <ServiceBlock key={index} data={service.data} isExtra />
      ))}
    </Section>
  );
};

const Title = ({ title }) => {
  return (
    <Paragraph
      sx={{
        fontFamily: 'primary.normal',
        lineHeight: 1.5,
        fontSize: ['2rem', '2.4rem', '2.2rem', '2rem', '2rem', '2.4rem'],
        gridColumn: [
          '1/13',
          '1/13',
          '3 / span 18',
          '3 / span 19',
          '1 / span 15',
          '2 / span 13'
        ],
        '& > span': {
          fontFamily: 'primary.bold'
        }
      }}
      dangerouslySetInnerHTML={{ __html: convertApiHtmlText(title) }}
    />
  );
};

const ServiceBlock = ({
  data: { service, title, description },
  isExtra = false
}) => {
  return (
    <Box
      className="service-block"
      sx={{
        gridColumn: [
          '1/13',
          '1/13',
          '3 / span 19',
          '3 / span 20',
          '1/25',
          '2 / span 21'
        ],
        mt: ['7.1rem', '8rem', '6.2rem', '6.2rem', '10rem', '9.7rem'],
        '&:not(:first-of-type)': {
          mt: ['14rem', '14rem', '14rem', '14rem', '14rem', '9.7rem']
        },
        display: [null, null, null, null, 'grid', 'grid'],
        gridTemplateColumns: [
          null,
          null,
          null,
          null,
          '28.6rem 1fr',
          '31.6rem 1fr'
        ],
        columnGap: [null, null, null, null, '9.2rem', '11.6rem']
      }}>
      <Box className="heading-block">
        <Heading
          as="h2"
          sx={{
            fontFamily: 'primary.bold',
            lineHeight: 1.2,
            fontSize: [
              '2.4rem',
              '3.2rem',
              '3.2rem',
              '2.8rem',
              '2.8rem',
              '3.2rem'
            ]
          }}>
          {title}
        </Heading>
        {isExtra && (
          <Paragraph
            sx={{
              fontFamily: 'body.medium',
              lineHeight: 1,
              fontSize: [
                '1.5rem',
                '1.6rem',
                '1.6rem',
                '1.5rem',
                '1.5rem',
                '1.6rem'
              ],
              mt: ['0.4rem', '0.4rem', '0.8rem', '0.8rem', '0.8rem', '0.8rem']
            }}>
            {description}
          </Paragraph>
        )}
      </Box>
      <ServiceList services={service} isExtra={isExtra} />
    </Box>
  );
};

const ServiceList = ({ services, isExtra }) => {
  return (
    <Box
      as="ul"
      className="service-list"
      sx={{
        mt: ['2.8rem', '2.8rem', '2.8rem', '2.8rem', 0, 0] // for space between title and the list
      }}>
      {services.map((serviceItem, index) => {
        return <ListItem key={index} data={serviceItem} isExtra={isExtra} />;
      })}
    </Box>
  );
};

const ListItem = ({ data: { name, description }, isExtra }) => {
  return (
    <>
      <DividerLine
        animated
        sx={{
          '&:last-of-type)': {
            display: 'none' // for the last line in the list
          }
        }}
      />
      <Box
        as="li"
        sx={{
          display: 'grid',
          gridTemplateColumns: 'auto 1fr',
          alignItems: 'center',
          py: ['1.2rem', '1.6rem', '1.6rem', '1.6rem', '1.6rem', '1.6rem']
        }}>
        {!isExtra && (
          <ListDot
            sx={{
              mr: ['1.1rem', '2.6rem', '1.4rem', '1.3rem', '2.6rem', '3.8rem']
            }}
          />
        )}
        <Box>
          <Paragraph
            sx={{
              fontFamily: 'body.medium',
              lineHeight: 1.5,
              fontSize: [
                '1.5rem',
                '2rem',
                '1.8rem',
                '1.6rem',
                '1.8rem',
                '1.8rem'
              ]
            }}>
            {name}
          </Paragraph>
          {description !== '' && (
            <Paragraph
              sx={{
                fontFamily: 'body.medium',
                lineHeight: 1.5,
                fontSize: [
                  '1.2rem',
                  '1.6rem',
                  '1.5rem',
                  '1.4rem',
                  '1.5rem',
                  '1.5rem'
                ]
              }}>
              {description}
            </Paragraph>
          )}
        </Box>
      </Box>
    </>
  );
};
