import React from 'react';

// External Components
import { Heading } from '@thepuzzlers/pieces';
import { formatEurPrice, getPlanPrice } from 'utils';

export const FloorInfoTitle = ({ title, price, name = '' }) => {
  const floorPrice = price ? `, ${getPlanPrice(price)}` : '';

  return (
    <Heading
      className="__floor-info-title"
      as="h3"
      sx={{
        fontFamily: 'primary.bold',
        lineHeight: 1,
        fontSize: ['1.8rem', '2.4rem', '2.3rem', '2rem', '2.4rem', '2.4rem']
      }}>
      {`${title} ${name}${floorPrice}`}
    </Heading>
  );
};
