import React from 'react';
import { Helmet } from 'react-helmet';

// Sections
import { SEO } from 'gatsby-theme-thepuzzlers-intl';

// Local Components
import { Spacer } from 'components';
import {
  Header,
  FloorPlans,
  PersonalizedHome,
  Services
} from 'sections/produktdetailsseitePage';

// Cms Data
import { useKisProduktdetailsseitePageData, useSiteData } from 'graphqlHooks';

// Utils
import { convertStringToSlug } from 'utils';

const ProductDetail = (props) => {
  const productData = props.pageContext.data;
  const siteData = useSiteData();
  const siteUrl = siteData.nodes[0].siteMetadata.url;

  const productName =
    productData.shortName?.replace('<br/>', ' ') || productData.name;

  const staticImageURL =
    productData.thumbnail_image?.url ||
    `${siteUrl}/${productData.thumbnail_image.publicURL}`;

  const { header, floor_plan_details, personalized_home, services } =
    useKisProduktdetailsseitePageData();

  function removeHTMLTags(htmlString) {
    if (!htmlString) return '';
    return htmlString.replaceAll(/<\/?[^>]+(>|$)/gi, '');
  }

  const shortDescription = removeHTMLTags(
    productData.short_description.html
  ).slice(0, 197);
  const url = `${siteUrl}/modulhaeuser/${convertStringToSlug(productName)}/`;

  return (
    <>
      <SEO
        title={productName}
        description={removeHTMLTags(productData.long_description.html).slice(
          0,
          160
        )}
        shortTitle={productName}
        shortDescription={
          shortDescription.slice(0, 197) +
          (shortDescription.length >= 197 ? '...' : '')
        }
        url={url}
      />
      <Helmet>
        {/* Facebook */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content={url} />
        <meta property="og:image" content={staticImageURL} />

        {/* Twitter */}
        <meta property="twitter:url" content={url} />
        <meta property="twitter:image" content={staticImageURL} />
      </Helmet>
      <Spacer
        height={['2rem', '5.6rem', '3.1rem', '3.8rem', '2.4rem', '2rem']}
      />
      <Header staticData={header} cmsData={productData} />
      <Spacer
        height={['12rem', '19.5rem', '18rem', '10.7rem', '23.8rem', '15.6rem']}
      />
      <FloorPlans staticData={floor_plan_details} cmsData={productData} />
      <Spacer
        height={[
          '31.8rem',
          '21.1rem',
          '30.8rem',
          '14.4rem',
          '23.2rem',
          '15.2rem'
        ]}
      />
      <PersonalizedHome
        data={personalized_home}
        image={productData.interior_image}
      />
      <Spacer
        height={[
          '18.9rem',
          '25.4rem',
          '23.3rem',
          '17.3rem',
          '30.6rem',
          '27.7rem'
        ]}
      />
      <Services data={services} />
      <Spacer
        height={[
          '28rem',
          '27.3rem',
          '21.2rem',
          '18.8rem',
          '18.6rem',
          '27.7rem'
        ]}
      />
    </>
  );
};

export default ProductDetail;
