import React from 'react';

// External Components
import { Box } from '@thepuzzlers/pieces';

// Self Component
import { FloorInfo, FloorInfoCaption, FloorInfoList, FloorInfoTitle } from '.';
import { AspectRatioImageContainer, GridSpacer } from 'components';
import { GridWrapper } from '@thepuzzlers/pieces/dist/thepuzzlers-pieces.cjs.prod';

// Utils

export const FloorPlanWithLevels = ({
  number = '',
  staticData,
  modules,
  livingArea,
  width,
  length,
  footprint,
  rooms = [],
  levels = [],
  price,
  name
}) => {
  const {
    modules_label,
    living_area_label,
    length_label,
    width_label,
    list_caption,
    list_title,
    floor_space_label
  } = staticData;

  const listOne = [
    {
      name: modules_label,
      size: modules
    },
    {
      name: length_label,
      size: length
    },
    {
      name: width_label,
      size: width
    },
    {
      name: floor_space_label,
      size: footprint
    }
  ];

  const listTwo = [
    {
      name: living_area_label,
      size: livingArea
    },
    ...rooms
  ];

  return (
    <GridWrapper className="__floor-plan-with-levels-container">
      <FloorInfoTitleAndCaption
        listTitle={list_title}
        listCaption={list_caption}
        number={number}
        name={name}
        price={price}
      />
      <GeneralFloorInfoSpacer />
      <GeneralFloorInfo listOne={listOne} listTwo={listTwo} />
      <FloorPlanSketchSpacer />
      <GroundFloorPlan image={levels[0].image} />
      <LevelFloorInfoSpacer />
      <GroundFloorInfo rooms={levels[0].rooms} title={levels[0].name} />
      <UpperFloorPlan image={levels[1].image} />
      <UpperFloorInfo rooms={levels[1].rooms} title={levels[1].name} />
    </GridWrapper>
  );
};

const FloorInfoTitleAndCaption = ({
  listTitle,
  listCaption,
  number = '',
  name,
  price
}) => {
  return (
    <Box
      sx={{
        gridColumn: [
          '1/13',
          '1/13',
          '1/ span 14',
          '1/13',
          '1/ span 10',
          '3/ span 8'
        ]
      }}>
      <FloorInfoTitle
        title={listTitle}
        number={number}
        name={name}
        price={price}
      />
      <FloorInfoCaption
        text={listCaption}
        sx={{
          mt: ['1.2rem', '1.2rem', '1.2rem', '1.2rem', '1.2rem', '1.2rem']
        }}
      />
    </Box>
  );
};

const GeneralFloorInfo = ({ listOne, listTwo }) => {
  return (
    <>
      <FloorInfoList
        className="__general-floor-info"
        data={[...listOne, ...listTwo]}
        sx={{
          gridColumn: ['1/13', '1/13', null, null, null, null],
          mt: ['3.8rem', '3.8rem', null, null, null, null],
          display: [null, null, 'none', 'none', 'none', 'none']
        }}
      />
      <FloorInfoList
        className="__left-list"
        data={listOne}
        sx={{
          display: ['none', 'none', 'block', 'block', 'block', 'block'],
          gridColumn: [
            null,
            null,
            '1/ span 11',
            '1/ span 11',
            '1/ span 10',
            '3/ span 8'
          ],
          mt: 0,
          alignSelf: 'start'
        }}
      />
      <FloorInfoList
        className="__right-list"
        data={listTwo}
        sx={{
          display: ['none', 'none', 'block', 'block', 'block', 'block'],
          gridColumn: [null, null, '14/25', '14/25', '15/25', '15/ span 8'],
          mt: 0,
          alignSelf: 'start'
        }}
      />
    </>
  );
};

const GroundFloorPlan = ({ image }) => {
  return (
    <FloorPlanSketch
      image={image}
      sx={{
        gridColumnStart: [1, 1, 3, 3, 1, 2]
      }}
    />
  );
};

const GroundFloorInfo = ({ rooms, title }) => {
  return (
    <LevelFloorInfo
      rooms={rooms}
      title={title}
      sx={{
        gridColumnStart: [1, 1, 4, 5, 1, 3]
      }}
    />
  );
};

const UpperFloorPlan = ({ image }) => {
  return (
    <FloorPlanSketch
      image={image}
      sx={{
        gridColumnStart: [1, 1, 3, 3, 13, 13],
        gridRow: [null, null, null, null, 5, 5]
      }}
    />
  );
};

const UpperFloorInfo = ({ rooms, title }) => {
  return (
    <LevelFloorInfo
      rooms={rooms}
      title={title}
      sx={{
        gridColumnStart: [1, 1, 4, 5, 15, 15]
      }}
    />
  );
};

// Reusable components
const FloorPlanSketch = ({ image: { image, alt }, sx }) => {
  return (
    <AspectRatioImageContainer
      aspectRatios={1}
      src={image}
      alt={alt}
      sx={{
        gridColumnEnd: [13, 13, 'span 20', 'span 20', 'span 12', 'span 11'],
        // mt: ['10rem', '11rem', '11rem', '11rem', '11rem', '8rem'],
        mt: ['10rem', '11rem', '11rem', '11rem', 0, 0],
        ...sx
      }}
    />
  );
};

const LevelFloorInfo = ({ rooms, title, sx }) => {
  return (
    <FloorInfo
      data={rooms}
      listTitle={title}
      sx={{
        gridColumnEnd: [13, 13, 'span 18', 'span 16', 'span 10', 'span 8'],
        mt: ['3.8rem', '4rem', '4rem', '3.8rem', 0, 0],
        ...sx
      }}
    />
  );
};

const GeneralFloorInfoSpacer = () => {
  return (
    <GridSpacer
      height={[null, null, '3.8rem', '3.8rem', '3.8rem', '3.8rem']}
      sx={{
        display: ['none', 'none', 'block', 'block', 'block', 'block']
      }}
    />
  );
};

const FloorPlanSketchSpacer = () => {
  return (
    <GridSpacer
      height={[null, null, null, null, '11rem', '8rem']}
      sx={{
        display: ['none', 'none', 'none', 'none', 'block', 'block']
      }}
    />
  );
};

const LevelFloorInfoSpacer = () => {
  return (
    <GridSpacer
      height={[null, null, null, null, '8rem', '8rem']}
      sx={{
        display: ['none', 'none', 'none', 'none', 'block', 'block']
      }}
    />
  );
};
