import React from 'react';

// External Components
import { Section, Heading, Box, Paragraph } from '@thepuzzlers/pieces';

// Utils
import { convertApiHtmlText } from 'graphqlHooks/helper';
import { ImageContainer } from 'components';

export const PersonalizedHome = ({
  data: { title, richtextdescription: description, image_caption },
  image
}) => {
  return (
    // Markup
    <Section id="product-detail-page__personalized-home">
      <Headline data={convertApiHtmlText(title.html)} />
      <Description data={description.html} />
      <Image {...image} />
      <ImageCaption data={image_caption} />
    </Section>
  );
};

// Elements

const Headline = ({ data }) => (
  <Heading
    as="h2"
    dangerouslySetInnerHTML={{
      __html: data
    }}
    sx={{
      gridColumn: [
        '1/13',
        '1/span 10',
        '1/span 15',
        '1/span 13',
        '14/span 10',
        '15/span 7'
      ],
      gridRow: [1, 1, 1, 1, 2, 2],
      fontFamily: 'primary.normal',
      fontSize: ['2.4rem', '3.2rem', '3.2rem', '2.8rem', '3.2rem', '2.8rem'],
      fontFeatureSettings:
        "'salt' on, 'ss01' on, 'ss02' on, 'ss03' on, 'ss04' on, 'ss05' on, 'ss08' on, 'calt' off",
      lineHeight: 1.2,
      '& > span': {
        fontFamily: 'primary.bold'
      }
    }}
  />
);

const Description = ({ data }) => (
  <Paragraph
    sx={{
      fontFamily: 'body.normal',
      fontSize: ['1.5rem', '1.8rem', '1.6rem', '1.4rem', '1.6rem', '1.5rem'],
      gridColumn: [
        '1/13',
        '1/span 11',
        '1/span 15',
        '1/span 13',
        '14/span 10',
        '15/span 9'
      ],
      gridRow: [2, 2, 2, 2, 3, 3],
      lineHeight: 1.5,
      mt: ['2.4rem', '2.4rem', '2.4rem', '2.4rem', '2.4rem', '4.8rem'],
      '& > span': {
        fontFamily: 'body.bold'
      }
    }}
    dangerouslySetInnerHTML={{ __html: convertApiHtmlText(data) }}
  />
);

const Image = ({ image, alt }) => (
  <ImageContainer
    src={image}
    imageAlt={alt}
    sx={{
      gridColumn: ['1/13', '1/13', '7/25', '7/25', '1/span 12', '2/span 12'],
      gridRow: [3, 3, 3, 3, '1/span 3', '1/span 3'],
      height: ['36.9rem', '36.6rem', '37.3rem', '28rem', '35.2rem', '36.9rem'],
      mt: ['4.7rem', '9.6rem', '6.4rem', '8.1rem', 0, 0]
    }}
  />
);

const ImageCaption = ({ data }) => (
  <Paragraph
    sx={{
      fontFamily: 'body.normal',
      fontSize: ['1.4rem', '1.6rem', '1.4rem', '1.4rem', '1.4rem', '1.4rem'],
      gridColumn: [
        '1/span 8',
        '1/span 6',
        '7/span 7',
        '7/span 7',
        '1/span 5',
        '2/span 4'
      ],
      lineHeight: 1.5,
      mt: ['1.6rem', '1.6rem', '1.2rem', '1.6rem', '2rem', '1.6rem']
    }}>
    {data}
  </Paragraph>
);
