import React from 'react';
// External component

// Local Components
import { AspectRatioImageContainer } from 'components';

// Self Components
import { FloorInfo } from '.';
import { GridWrapper } from '@thepuzzlers/pieces/dist/thepuzzlers-pieces.cjs.prod';

export const FloorPlanWithoutLevel = ({
  number = '',
  staticData,
  modules,
  livingArea,
  width,
  length,
  footprint,
  rooms = [],
  sketchImage,
  price,
  name
}) => {
  const {
    modules_label,
    living_area_label,
    length_label,
    width_label,
    list_caption,
    list_title,
    floor_space_label
  } = staticData;

  const floorInfo = [
    {
      name: modules_label,
      size: modules
    },
    {
      name: length_label,
      size: length
    },
    {
      name: width_label,
      size: width
    },
    {
      name: floor_space_label,
      size: footprint
    },
    {
      name: living_area_label,
      size: livingArea
    },
    ...rooms
  ];

  return (
    <GridWrapper className="__floor-plan-without-level-container">
      <Sketch data={sketchImage} />
      <FloorInfo
        number={number}
        data={floorInfo}
        listTitle={list_title}
        listCaption={list_caption}
        price={price}
        name={name}
        sx={{
          gridRow: [null, null, null, null, 1, 1],
          gridColumn: [
            '1/13',
            '1/13',
            '4/ span 18',
            '4/ span 18',
            '2/ span 10',
            '3/ span 8'
          ]
        }}
      />
    </GridWrapper>
  );
};

const Sketch = ({ data: { image, alt } }) => (
  <AspectRatioImageContainer
    src={image}
    alt={alt}
    aspectRatios={1}
    sx={{
      alignSelf: 'start',
      gridColumn: [
        '1/13',
        '1/13',
        '3/ span 20',
        '3/ span 20',
        '13/25',
        '12/ span 11'
      ]
    }}
  />
);
