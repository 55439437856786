import React from 'react';

// External Components
import { Box, Paragraph } from '@thepuzzlers/pieces';

// Local Components
import { DividerLine } from 'components';

// Utils
import { formatSquareMeterWithSuperscript } from 'utils';

export const FloorInfoList = ({ data, sx, className = '' }) => {
  return (
    <Box
      className={`__floor-info-list ${className}`}
      as="ul"
      sx={{
        mt: ['2rem', '2.8rem', '3rem', '2.4rem', '3.2rem', '4rem'],
        ...sx
      }}>
      {data.map((item) => {
        const size =
          typeof item.size === 'string'
            ? formatSquareMeterWithSuperscript(item.size)
            : item.size;

        return (
          <>
            <Box
              as="li"
              key={item.name}
              sx={{
                display: 'grid',
                gridTemplateColumns: [
                  '1fr 12rem',
                  '1fr 16.6rem',
                  '1fr 13.6rem',
                  '1fr 10.8rem',
                  '1fr 16rem',
                  '1fr 14.6rem'
                ]
              }}>
              <ListText text={item.name} />
              <ListText
                text={size}
                sx={{
                  fontFamily: 'body.bold',
                  textAlign: 'right'
                }}
              />
            </Box>
            <ListDivider />
          </>
        );
      })}
    </Box>
  );
};

const ListText = ({ text, sx }) => {
  return (
    <Paragraph
      as="span"
      sx={{
        fontFamily: 'body.normal',
        fontFeatureSettings: "'ss05' on, 'ss08' on, 'calt' off",
        fontSize: ['1.4rem', '1.8rem', '1.6rem', '1.4rem', '1.6rem', '1.4rem'],
        lineHeight: 1.5,
        alignSelf: 'center',
        position: 'relative',
        ...sx
      }}
      dangerouslySetInnerHTML={{ __html: text }}
    />
  );
};

const ListDivider = () => {
  return (
    <DividerLine
      sx={{
        my: ['1rem', '1rem', null, null, null, '1.2rem'],
        '&:last-of-type': {
          display: 'none'
        }
      }}
    />
  );
};
